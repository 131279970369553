<script>
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="1920"
  viewBox="0 0 1920 192.988"
>
  <defs>
    <linearGradient
      id="heroGradientFill"
      x1="0.5"
      x2="2.069"
      y2="1.892"
      gradientUnits="objectBoundingBox"
    >
      <stop offset="0" stop-color="var(--primary)" />
      <stop offset="1" stop-color="var(--primary-variant)" />
    </linearGradient>
  </defs>
  <path
    id="Path_7"
    data-name="Path 7"
    d="M0,0S494.627,69.721,974.627,87.156,1920,69.74,1920,69.74V192.988s-447.958-47.168-927.958-36.171S0,192.988,0,192.988Z"
    fill="url(#heroGradientFill)"
  />
</svg>

<style>
  svg {
    width: 100%;
  }
</style>
